<template>

<app-item route="Text" :id="item.id" id-name="text" :selected="selected" v-on:shiftclick="$emit('shiftclick')">

	<app-icon class="item-icon" :icon="'text.type.' + item.type" />

	<app-item-title :text="$highlight(item.title, highlight)" />

	<app-item-count route="Text" :params="{id: item.id}" tooltip="Groups and locations using this block" :count="groupCount" />

</app-item>

</template>

<script>

export default {

	props: ['item', 'highlight', 'selected'],

	computed: {

		groupCount: function() {

			return this.$_.filter(this.$store.getters['groups'], function(group) {

				return this.$_.contains(group.texts, this.item.id)

			}.bind(this)).length + this.$_.filter(this.$store.getters['locations'], function(group) {

				return this.$_.contains(group.texts, this.item.id)

			}.bind(this)).length

		}

	}

}

</script>

<style scoped>

.item-icon {
	width: 32px;
	height: 32px;
	line-height: 32px;
	border-radius: 4px;
	flex-shrink: 0;
	margin-right: 10px;
	background-color: #4082d3;
	text-align: center;
	color: #fff;
}

</style>
